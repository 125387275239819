import React from 'react'
import { Link } from 'gatsby'
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"
import p01VO from "../audio/p01VO.m4a"
import Footer from "../components/Footer"

function importAll(r) {
    let images = {};
    r.keys().map((item, index) => { images[item.replace('./', '')] = r(item); });
    return images;
}
const images = importAll(require.context('../images/', false, /.jpg$|.png/));

function playAudio() {
  const audioEl = document.getElementsByClassName("audio-element")[0]
  audioEl.play()
}

const Page01 = ({ data }) => {
  return (
  <div className="main-container">
    <Helmet>
        <title>{data.site.siteMetadata.title}</title>
        <link href="https://fonts.googleapis.com/css?family=Fira+Sans&display=swap" rel="stylesheet" />
    </Helmet>
    <Link to="/Page02">
      <div className="page-turner right-page-pos">
      <img style={{opacity: 0.75}} className="hw" src={images["arrowPages.png"]} alt="" />
      </div>
    </Link>
    <div className="centered-container">
      <div className="_idContainer000">
        <div className="page-turner">
        </div>
        <div className="backContainer004">
          <img className="hw" src={images["p01_back.jpg"]} alt="" />
        </div>
        <div className="_idContainer005">
          <div className="_idContainer003">
            <div className="_idContainer001" onClick={playAudio}>
              <img className="hw" src={images["p01_botonCuentamelo.png"]} alt="" />
            </div>
          </div>
        <div className="_idContainer004">
          <img className="hw" src={images["p01_letrero.png"]} alt="" />
          <div className="creditos">
            Historia: Ana Coronado | Ilustraciones: Paulina Coronado
          </div>
        </div>
        </div>
      <div className="_idContainer006">
      <img className="_idGenObjectAttribute-1 _idGenObjectAttribute-2" src={images["p01_letreroPoste.png"]} alt="" />
    </div>
      </div>
      <audio className="audio-element">
        <source src={p01VO}></source>
      </audio>

    </div>
  <Footer />
  </div>
  )
}
///
export const query = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
export default Page01
